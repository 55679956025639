import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Flex,
    Text,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    SimpleGrid,
    VStack,
} from "@chakra-ui/react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { ReferenceLine } from "recharts";

export default function PrivateSection() {
    const [arrQ, setArrQ] = useState([]);
    const [arrQPrediction, setArrQPrediction] = useState([]);
    const [specificDay, setSpecificDay] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newDate, setNewDate] = useState(new Date());
    const [newQ, setNewQ] = useState("");
    const [editingRow, setEditingRow] = useState(null);
    const formatDate = (date) => {
        if (!date) return "";
        return new Date(date).toISOString().split('T')[0];
    }
    useEffect(() => {
        async function fetchData() {

            await getData();
        }
        fetchData();
    }, []);

    const handleEditOpen = (row) => {
        setEditingRow(row);
        setNewDate(row.Date);
        setNewQ(row.Q);
        onOpen();
    };

    const handleEditSave = async () => {
        if (editingRow) {
            const { data, error } = await supabase
                .from("x_private_table")
                .update({ Date: newDate, Q: newQ })
                .match({ id: editingRow.id });

          if (error) {
              console.error("Error updating record:", error);
          } else {
              getData(); // Refresh data
              onClose(); // Close the modal
              setEditingRow(null); // Reset editing row
          }
      }
  };

    const generateDateArray = (numberOfDays) => {
        const dates = [];
        const currentDate = new Date();
        for (let i = 0; i < numberOfDays; i++) {
            dates.push(
                new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + i)
                    .toISOString()
                    .split('T')[0]
            );
        }
        return dates;
    };

    async function getData() {
        try {
            const { data, error } = await supabase
                .from("x_private_table")
          .select("*");

        if (error) {
            console.error("Error", error);
        } else {
              let actualQ = 0;
              const modifiedData = data.map((row) => {
                  actualQ += parseFloat(row.Q || 0);
                  return { ...row, ActualQ: actualQ };
              });
              let maxNumber = modifiedData[0].Q;
              setArrQ(modifiedData);

              for (let i = 1; i < modifiedData.length; i++) {
                  if (modifiedData[i].Q > maxNumber) {
                      maxNumber = modifiedData[i].Q;
                  }
              }
              console.log((maxNumber - actualQ).toFixed(2));
              let media = 0;
              modifiedData.forEach((element) => {
                  if (element.Q != maxNumber) {
                      console.log(element.Q);
                      media += element.Q;
                  }
              });
              media = (media / (modifiedData.length - 1)).toFixed(2);

              //
            let prevision = parseFloat(maxNumber);
            var once = 0
            let arrPrevDate = generateDateArray(30); // Generates an array of the next 30 days
              const modifiedDataPrevision = arrPrevDate.map((row) => {
                  prevision = prevision + parseFloat(media);
                  if (prevision + parseFloat(media) <= 0 && once == 0) {
                      setSpecificDay(row)
                      once = 1
                  }
                  console.log(media);
                  return { Date: row, PrevisionQ: prevision };
              });

              setArrQPrediction(modifiedDataPrevision);
              console.log(arrQPrediction);
              console.log(arrQ);
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  }

    const handleAddRecord = async () => {
        const { data, error } = await supabase
            .from("x_private_table")
            .insert([{ Date: newDate, Q: newQ }]);

      if (error) {
          console.error("Error adding record:", error);
      } else {
          getData(); // Refresh data
          onClose(); // Close the modal
      }
  };

    const handleDelete = async (id) => {
        const { data, error } = await supabase
            .from("x_private_table")
            .delete()
            .match({ id: id });

      if (error) {
          console.error("Error deleting record:", error);
      } else {
          getData(); // Refresh data after deletion
      }
  };

    const getTotal = () => {
        return arrQ.reduce((total, row) => total + parseFloat(row.Q || 0), 0);
    };

    return (
      <div>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              {/* Table on the left */}
              <Box p={5}>
                  <Table variant="striped" colorScheme="gray">
                      {/* Table headers */}
                      <Thead>
                          <Tr>
                              <Th>Date</Th>
                              <Th>Q.</Th>
                              <Th>Action</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {/* Table rows */}
                          {arrQ.map((row, index) => (
                              <Tr key={index}>
                                  <Td>{row.Date}</Td>
                                  <Td>{row.Q}</Td>
                                  <Td>
                          <Button
                              size="sm"
                              colorScheme="blue"
                              onClick={() => handleEditOpen(row)}
                          >
                              Edit
                          </Button>
                          <Button
                              size="sm"
                              colorScheme="red"
                              onClick={() => handleDelete(row.id)}
                          >
                              Delete
                          </Button>
                      </Td>
                  </Tr>
              ))}
                      </Tbody>
                  </Table>
              </Box>

              {/* Graph on the right */}
              <Box p={5}>
                  <StatGroup>
                      <Stat>
                          <StatLabel>Total</StatLabel>
                            <StatNumber>{getTotal().toFixed(2)}</StatNumber>
                      </Stat>
                      <Button colorScheme="green" onClick={onOpen}>
                          Add
                      </Button>
                  </StatGroup>
              </Box>
          </SimpleGrid>
          <VStack spacing={4}>
              <Text fontSize={{ base: "md", md: "xl" }} fontWeight="bold">
                  Cumulative Sum of Q over Time
              </Text>
              <Box width="100%" height="300px">
                  <ResponsiveContainer>
                      <LineChart data={arrQ}>
                          <XAxis dataKey="Date" />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="ActualQ" stroke="#8884d8" />

                      </LineChart>
                  </ResponsiveContainer>
              </Box>
          </VStack>
          <VStack spacing={4}>
              <Text fontSize={{ base: "md", md: "xl" }} fontWeight="bold">
                  Prevision over the time
              </Text>
              <Box width="100%" height="300px">
                  <ResponsiveContainer>
                      <LineChart data={arrQPrediction}>
                          <XAxis dataKey="Date" />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="PrevisionQ" stroke="#8884d8" />
                            <ReferenceLine x={specificDay} stroke="red" label="Important Day" />

                      </LineChart>
                  </ResponsiveContainer>
              </Box>
          </VStack>
          {/* Modal */}
          <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                  <ModalHeader>
                      {editingRow ? "Edit Record" : "Add New Record"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                      <FormControl>
                          <FormLabel>Date</FormLabel>
                          <Input
                              type="date"
                              value={newDate}
                              onChange={(e) => setNewDate(e.target.value)}
                          />
                      </FormControl>
                      <FormControl mt={4}>
                          <FormLabel>Q</FormLabel>
                          <Input
                              value={newQ}
                              onChange={(e) => setNewQ(e.target.value)}
                          />
                      </FormControl>
                  </ModalBody>
                  <ModalFooter>
                      <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={editingRow ? handleEditSave : handleAddRecord}
                      >
                          {editingRow ? "Save Changes" : "Add"}
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
      </div>
  );
}
