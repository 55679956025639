import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useDisclosure,
    Box,
    Card,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";

export default function AdminPanel() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const user_id = JSON.parse(sessionStorage.getItem("user")).id;

    const confirmDelete = () => {
        console.log("log delte");
        deleteData();
        onClose();
    };

    async function deleteData() {
        const tableName = "Spesa_money_income_outcome"; // Replace with your table name

        try {
            const { data, error } = await supabase
                .from(tableName)
                .delete()
                .eq("account_uuid", user_id);

            if (error) {
                console.error("Error deleting data:", error);
            } else {
                console.log("Data deleted successfully:", data);
            }
        } catch (error) {
            console.error("Error deleting data:", error.message);
        }
    }

    return ( <
        Box display = "flex"
        justifyContent = "center"
        alignItems = "center"
        height = "100%" >
        <
        Card >
        <
        div style = {
            { padding: "16px" }
        } >
        <
        Button colorScheme = "red"
        onClick = { onOpen }
        size = "lg"
        width = "200px"
        variant = "outline" >
        Clear all <
        /Button> < /
        div > <
        /Card>

        <
        AlertDialog isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose } >
        <
        AlertDialogOverlay >
        <
        AlertDialogContent >
        <
        AlertDialogHeader fontSize = "lg"
        fontWeight = "bold" >
        Delete all income / outcome <
        /AlertDialogHeader>

        <
        AlertDialogBody >
        Are you sure ? You can 't undo this action afterwards. < /
        AlertDialogBody >

        <
        AlertDialogFooter >
        <
        Button ref = { cancelRef }
        onClick = { onClose } >
        Cancel <
        /Button> <
        Button colorScheme = "red"
        onClick = { confirmDelete }
        ml = { 3 } >
        Delete <
        /Button> < /
        AlertDialogFooter > <
        /AlertDialogContent> < /
        AlertDialogOverlay > <
        /AlertDialog> < /
        Box >
    );
}