import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import "../Css/LoginPanel.css"; // Import your updated stylesheet with new styles

async function login(email, password) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });

  if (error) {
    throw error;
  }

  return data.user;
}

export default function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setError("");
    try {
      const user = await login(username, password);
      if (error) {
        setError(error.message);
      } else {
        console.log("User:", user);
        var objUserSession = {
          email: user.email,
          role: user.role,
          id: user.identities[0].id,
        };
        sessionStorage.setItem("user", JSON.stringify(objUserSession));
        props.onSuccessfulLogin();
      }
    } catch (error) {
      setError("An error occurred during login.");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h1>Login</h1>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
}
