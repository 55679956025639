import React, { useEffect, useState } from "react";

import { Switch, FormControlLabel } from '@mui/material';
import WorkDayTable from "./WorkDayTable";
import WorkDayTableCompact from "./WorkDayTableCompact";

export function TableSwitch(){
    const [isTableCompact, setIsTableCompact] = useState(false);

    return(
        <>
        <FormControlLabel
        control={
          <Switch
            checked={isTableCompact}
            onChange={() => setIsTableCompact(!isTableCompact)}
            name="editModeSwitch"
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: 'white',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'green',
              },
            }}
          />
        }
        label={!isTableCompact ? 'Extended' : 'Compact'}
        sx={{
          margin: 1,
          '& .MuiTouchRipple-root': {
            top: '-10px',
            bottom: '-10px',
            right: '-10px',
            left: '-10px',
          },
        }}
      />
       {isTableCompact ? (
        <WorkDayTableCompact></WorkDayTableCompact>
      ):(
        <WorkDayTable></WorkDayTable>
      )}
    </>
    );
}