import React, { useEffect, useState } from "react";

import NavBarSpese from "./NavBarSpese";
import TableIncomeOutcome from "./TableIncomeOutcome";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import LoadBankFile from "./LoadBankFile";
import AdminPanel from "./AdminPanel";
import ReportsList from "./ReportsList";
import RefundList from "./RefundList"
import RecurringList from './RecurringLists'
import PrivateSection from './PrivateSection'
function ResponsiveAppBar() {
  const [path, setPath] = useState("Income_Outcome");
  const [refreshTable, setRefreshTable] = useState(false);
  const [lockTable, setLockTable] = useState(false);
  const navigate = (data) => {
    setPath(data);
  };
  const refresh = () => {
    setRefreshTable(true);
    console.log("refresh table");
    setRefreshTable(false);
  };
  const lock = () => {
    console.log("lock main");
    setLockTable(!lockTable);
  };
  const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
  };
  const theme = extendTheme({ config });
  // Create an array to store the mouse movement and click data

  // // Function to handle mouse movement
  // function handleMouseMove(event) {
  //   const { clientX, clientY } = event;
  //   mouseActions.push(`Mouse moved to (${clientX}, ${clientY})`);
  //   console.log(mouseActions)
  // }

  // Function to handle mouse clicks


  return (
    <ChakraProvider theme={theme}>
      <NavBarSpese choosePath={navigate} refresh={refresh} lock={lock}>
        {" "}
      </NavBarSpese>{" "}
      {path == "Income_Outcome" && (
        <TableIncomeOutcome refresh={refreshTable} lock={lockTable}>
          {" "}
        </TableIncomeOutcome>
      )}{" "}
      {path == "Upload_Bank_file" && <LoadBankFile> </LoadBankFile>}{" "}
      {path == "Admin_Panel" && <AdminPanel> </AdminPanel>}{" "}
      {path == "ReportsList" && <ReportsList> </ReportsList>}{" "}
      {path == "RefundList" && <RefundList> </RefundList>}{" "}
      {path == "RecurringList" && <RecurringList> </RecurringList>}{" "}
      {path == "PrivateSection" && <PrivateSection></PrivateSection>}
      
    </ChakraProvider>
  );
}
export default ResponsiveAppBar;
