import React from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { SearchIcon, BellIcon, SettingsIcon } from '@chakra-ui/icons';

const BottomNavbar = () => {
    return (
        <Box position="fixed" bottom="0" width="100%" boxShadow="sm" bg="white">
            <Flex justify="space-around" p="2">
                <IconButton
                    variant="ghost"
                    aria-label="Home"
                />
                <IconButton
                    variant="ghost"
                    icon={<SearchIcon />}
                    aria-label="Search"
                />
                <IconButton
                    variant="ghost"
                    icon={<BellIcon />}
                    aria-label="Notifications"
                />
                <IconButton
                    variant="ghost"
                    icon={<SettingsIcon />}
                    aria-label="Settings"
                />
            </Flex>
        </Box>
    );
}

export default BottomNavbar;
