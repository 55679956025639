import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Image, Stack, Heading, Text, Divider, Button, ButtonGroup } from '@chakra-ui/react';
import Report1 from '../../Media/Report_1.png'; // Adjust the path to where your image is located
import { supabase } from "../supabaseClient";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { SimpleGrid } from '@chakra-ui/react'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function ReportsList() {
    const user_id = JSON.parse(sessionStorage.getItem("user")).id;
    let fetchedData = []; // This will hold data fetched from Supabase
    let fetchedDataTotal = []; // This will hold data fetched from Supabase

    const generatePDF = (data, title) => {
        try {
            const documentDefinition = {
                content: [
                    {
                        text: `${title} Report`,
                        style: 'header',
                        alignment: 'center',
                        margin: [0, 0, 0, 20], // Margin at the bottom to create space
                    },
                    {
                        text: getCurrentDate(), // Add the current date
                        style: 'date',
                        alignment: 'right',
                        margin: [0, 0, 0, 20], // Margin at the bottom to create space
                    },
                    {
                        ul: data.map(item => {
                            return [
                                { text: `Date: ${item.date}`, style: 'listItemDate' }, // Add the date field
                                { text: `Amount: ${item.amount}`, style: 'listItemValue' },
                                { text: `Description:`, style: 'listItemDescription' },
                                item.description,
                                { text: '', margin: [0, 0, 0, 10] }, // Margin at the bottom of each item
                            ];
                        }),
                        style: 'listItem',
                    },
                ],
                styles: {
                    header: {
                        fontSize: 24,
                        bold: true,
                        color: '#007BFF',
                    },
                    date: {
                        fontSize: 12,
                        color: '#777',
                    },
                    listItemDate: {
                        fontSize: 12,
                        bold: true,
                        color: '#333',
                        margin: [0, 0, 0, 5],
                    },
                    listItemValue: {
                        fontSize: 14,
                        bold: true,
                        color: '#333',
                        margin: [0, 0, 0, 5],
                    },
                    listItemDescription: {
                        fontSize: 12,
                        color: '#777',
                        margin: [0, 0, 0, 5],
                    },
                },
            };

            pdfMake.createPdf(documentDefinition).download(`${title}_Report.pdf`);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    async function getData() {
        try {
            const { data, error } = await supabase
                .rpc("get_report_montly", { account_uuid_param: user_id })
            if (error) {
                console.error("Error fetching data:", error);
            } else {
                fetchedData = data;
                console.log('Monthly Data fetched:', data);
            }
        } catch (error) {
            console.error("Error in getData:", error);
        }
    }
    async function getDataTotal() {
        try {
            const { data, error } = await supabase
                .from("Spesa_money_income_outcome")
                .select("*")
                .eq("account_uuid", user_id)
                .order("date", { ascending: false });

            if (error) {
                console.error("Error fetching data:", error);
            } else {
                fetchedDataTotal = data;
                console.log('Total Data fetched:', data);
            }
        } catch (error) {
            console.error("Error in getData:", error);
        }
    }

    const getCurrentDate = () => {
        const currentDate = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return currentDate.toLocaleDateString(undefined, options);
    };

    const generateMonthly = async () => {
        console.log('Generate Monthly Report clicked');
        await getData();
        generatePDF(fetchedData, 'Monthly');
    };
    const generateTotal = async () => {
        console.log('Generate Total Report clicked');
        await getDataTotal();
        generatePDF(fetchedDataTotal, 'Total');
    };

    return (
        <>
            <SimpleGrid columns={3} spacing={1}>
                <Card maxW='sm'>
                    <CardBody>
                        <Image
                            src={Report1}
                            alt='Green double couch with wooden legs'
                            borderRadius='lg'
                        />
                        <Stack mt='6' spacing='3'>
                            <Heading size='md'>Monthly Extraction</Heading>
                            <Text>
                                Estrazione resoconto mensile.
                            </Text>
                            <Text color='blue.600' fontSize='2xl'>
                                1sec.
                            </Text>
                        </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <ButtonGroup spacing='2'>
                            <Button variant='solid' colorScheme='blue' onClick={generateMonthly}>
                                Generate
                            </Button>
                        </ButtonGroup>
                    </CardFooter>
                </Card>
                <Card maxW='sm'>
                    <CardBody>
                        <Image
                            src={Report1}
                            alt='Green double couch with wooden legs'
                            borderRadius='lg'
                        />
                        <Stack mt='6' spacing='3'>
                            <Heading size='md'>Total Extraction</Heading>
                            <Text>
                                Estrazione resoconto totale.
                            </Text>
                            <Text color='blue.600' fontSize='2xl'>
                                1sec.
                            </Text>
                        </Stack>
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <ButtonGroup spacing='2'>
                            <Button variant='solid' colorScheme='blue' onClick={generateTotal}>
                                Generate
                            </Button>
                        </ButtonGroup>
                    </CardFooter>
                </Card>

            </SimpleGrid>
        </>
    );
}
