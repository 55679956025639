import {
  Card, CardHeader, CardBody, CardFooter,
  SimpleGrid,
  Heading,
  Text,
  Button,
  Box,
  AbsoluteCenter,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { anticipate } from 'framer-motion';
import { setRef } from '@blueprintjs/core';

export default function Refund() {
  const user_id = JSON.parse(sessionStorage.getItem("user")).id;
  const [arrAnticipated, setArrAnticipated] = useState([])
  const toast = useToast();
  const [refresh, setRefresh] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)

  // Function to calculate the total amount whenever arrAnticipated is updated
  const calculateTotalDeb = () => {
    let total = 0;
    arrAnticipated.forEach((row) => {
      total += row.amount;
    });
    setTotalAmount(total);
  }

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [refresh]);

  useEffect(() => {
    // Calculate total amount whenever arrAnticipated is updated
    calculateTotalDeb();
  }, [arrAnticipated]);

  const handleReimbursed = async (row) => {
    console.log(row)
    const { error } = await supabase
      .from("Spesa_money_income_outcome")
      .update({
        anticipated: !row.anticipated,
      })
      .eq("id", row.id);

    if (error) {
      console.error("Error updating:", error);
    } else {
      toast({
        title: "Update successful.",
        description: "The selected row has been updated.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setRefresh(true);
    }
  };

  async function getData() {
    try {
      const { data, error } = await supabase
        .from("Spesa_money_income_outcome")
        .select("*")
        .eq("account_uuid", user_id)
        .eq("anticipated", true)
        .order("date", { ascending: false });

      if (error) {
        console.error("Error", error);
      } else {
        setArrAnticipated(data);
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <>
      <SimpleGrid columns={1} spacing={10}>
        <Card>
          <CardHeader>
            <Heading size='md'>{totalAmount}</Heading>
          </CardHeader>
          <CardBody>
            <Text>Total to recieve</Text>
          </CardBody>
          <CardFooter>
            <SimpleGrid columns={2} spacing={3}>
              <Button>Total Reimbursed</Button>
            </SimpleGrid>
          </CardFooter>
        </Card>
      </SimpleGrid>
      <br></br>
      {totalAmount != 0 ? < Text fontSize='5xl'>Anticipated</Text > : <Text>No Anticipated money</Text>}
      <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
        {arrAnticipated.map((row, index) => (
          <Card key={index}>
            <CardHeader>
              <Heading size='md'>{row.amount}</Heading>
            </CardHeader>
            <CardBody>
              <Text>{row.description}</Text>
              <Text>{row.date}</Text>
            </CardBody>
            <CardFooter>
              <SimpleGrid columns={2} spacing={3}>
                <Button onClick={() => { handleReimbursed(row) }}>Reimbursed</Button>
              </SimpleGrid>
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>
    </>
  );
}
