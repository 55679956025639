import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Link,
  useColorModeValue,
  useDisclosure,
  HStack,
  VStack,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  AddIcon,
  SunIcon,
  MoonIcon,
} from "@chakra-ui/icons";
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { NotAllowedIcon } from '@chakra-ui/icons'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { PinInput, PinInputField } from '@chakra-ui/react'
export default function Navbar(props) {
  const { choosePath, refresh, lock } = props;
  const { isOpen: isNavOpen, onToggle: toggleNav } = useDisclosure();
  const initialRef = React.useRef(null);
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [enteredPin, setEnteredPin] = useState('');
  const CORRECT_PIN = "1234"; // Example PIN

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [description, setDescription] = useState("Description");
  const user_id = JSON.parse(sessionStorage.getItem("user")).id;

  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  const [lockIcon, setLockIcon] = useState();
  const secretCombinationSequenceRef = useRef(["Income_Outcome", "Admin_Panel", "ReportsList"]);
  const secretCombinationSequence = secretCombinationSequenceRef.current;

  const handleMenuClick = (page) => {
    // Check if the current page matches the next expected page in the sequence
    if (page === secretCombinationSequence[0]) {
      // Remove the matched page from the sequence
      secretCombinationSequence.shift();

      // If the sequence is empty, activate the secret combination
      if (secretCombinationSequence.length === 0) {
        setSecretCombinationActivated(true);
        console.log("Secret combination activated!");
        console.log(secretCombinationActivated)
        setTimeout(() => {
          setSecretCombinationActivated(false);
          console.log("Secret combination deactivated.");
          toggleNav()
        }, 10000); // Wait for 10 seconds before closing
      }
    }

    if (page === "Income_Outcome") {
      choosePath("Income_Outcome");
    } else if (page === "Dashboard") {
      navigate("/");
    } else if (page === "Upload_Bank_file") {
      choosePath("Upload_Bank_file");
    } else if (page === "Admin_Panel") {
      choosePath("Admin_Panel");
    }else if (page === 'ReportsList'){
      choosePath('ReportsList')
    } else if (page === 'RefundList') {
      choosePath('RefundList')
    } else if (page === 'RecurringList') {
      choosePath('RecurringList')
    }
    toggleNav();
  };
  const getValueCompat = (data) => {
    if (data == "Dashboard") {
      navigate("/");
    } else {
      choosePath(data);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(selectedDate, description);
    saveNewIncomeOutcome(selectedAmount, selectedDate, description);
  };
  async function saveNewIncomeOutcome(
    selectedAmount,
    selectedDate,
    description
  ) {
    const { data, error } = await supabase
      .from("Spesa_money_income_outcome")
      .insert([
        {
          amount: selectedAmount,
          date: selectedDate,
          description: description,
          account_uuid: user_id,
        },
      ]);

    if (error) console.log("Error:", error);
    else {
      refresh(true);
      console.log(refresh);
      setSelectedAmount(0);
      setSelectedDate(new Date());
      setDescription("Description");
      closeModal(true);
    }
  }

  const handleAmountChange = (valueAsString, valueAsNumber) => {
    setSelectedAmount(valueAsNumber);
  };

  const handleLock = () => {
    console.log("lock");
    lock(true);
    setLockIcon(!lockIcon);
  };

  const handleDescriptionChange = (valueAsString) => {
    setDescription(valueAsString);
  };

  const handleAmountInputChange = (e) => {
    const value = e.target.value;

    // Regular expression to allow negative numbers and up to two decimal places
    // Adjust the regex as needed for your specific requirements
    const regex = /^-?\d*(\.\d{0,2})?$/;

    if (value === "" || regex.test(value)) {
      setSelectedAmount(value);
    }
  };
  const {
    isOpen: isPrivateModalOpen,
    onOpen: openPrivateModal,
    onClose: closePrivateModal,
  } = useDisclosure();
  const [secretCombinationActivated, setSecretCombinationActivated] = useState(false);

  // Define a sequence of clicks or key presses to activate the secret combination

  // ... (existing code)
  const verifyCorrectPin = () => {
    if (enteredPin === CORRECT_PIN) {
      console.log("Correct PIN entered");
      // Add any additional actions you want to perform on correct PIN entry
      choosePath('PrivateSection')
      toggleNav()
      closePrivateModal()
    } else {
      console.log("Incorrect PIN");
      // Handle incorrect PIN entry
    }
  };
  const privateArea = () => {
    if (secretCombinationActivated) {
      openPrivateModal();
      setTimeout(() => {
        closePrivateModal();
        setSecretCombinationActivated(false);
        console.log("Secret combination deactivated.");
        toggleNav()
      }, 10000); // Wait for 10 seconds before closing
    } else {
      console.log("Secret combination not activated.");
    }
  };

  return (
    <>
      <Box>
        <Flex
          bg={useColorModeValue("gray.100", "gray.900")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
          justify={"space-between"}
        >
          <IconButton
            size={"md"}
            icon={isNavOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={toggleNav}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box>Wallet</Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              <NavLink onClick={() => handleMenuClick("Income_Outcome")}>
                Income/Outcome
              </NavLink>
              <NavLink onClick={() => handleMenuClick("Upload_Bank_file")}>
                Upload bank file
              </NavLink>
              <NavLink onClick={() => handleMenuClick("RefundList")}>Refund</NavLink>
              <NavLink onClick={() => handleMenuClick("RecurringList")}>Recurring</NavLink>

              <NavLink onClick={() => handleMenuClick("ReportsList")}> Reports</NavLink>
              <NavLink onClick={() => handleMenuClick("Admin_Panel")}>
                Admin Panel
              </NavLink>
              <NavLink onClick={() => handleMenuClick("Dashboard")}>
                Dashboard
              </NavLink>

            </HStack>
          </HStack>
          <HStack alignItems={"center"} spacing={4}>
            {lockIcon ? (
              <Button> <Tooltip label="Lock mode">
                <UnlockIcon onClick={() => handleLock()}> </UnlockIcon>
              </Tooltip></Button>
            ) : (
                <Button>  <Tooltip label="Unlock">
                <LockIcon onClick={() => handleLock()}> </LockIcon>
                </Tooltip></Button>
            )}
            {secretCombinationActivated && <Button onClick={privateArea}>🔑</Button>}
            <Button
              variant={"solid"}
              colorScheme={"teal"}
              size={"sm"}
              mr={4}
              leftIcon={<AddIcon />}
              onClick={openModal}
            >
              Add Funds
            </Button>
            <IconButton
              size={"md"}
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              aria-label={"Toggle Color Mode"}
              onClick={toggleColorMode}
            />
          </HStack>
        </Flex>

        <Collapse in={isNavOpen} animateOpacity>
          <MobileNav choosePath={getValueCompat} toggleNav={toggleNav} />
        </Collapse>
      </Box>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isModalOpen} // Changed to isModalOpen
        onClose={closeModal} // Changed to closeModal
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register the income/outcome</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Amount</FormLabel>

              <Input
                value={selectedAmount}
                onChange={handleAmountInputChange}
                placeholder="Enter amount"
                type="text"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Editable onChange={handleDescriptionChange} value={description}>
                <EditablePreview />
                <EditableTextarea />
              </Editable>{" "}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <ReactDatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleSubmit} colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isPrivateModalOpen}
        onClose={closePrivateModal}
        isCentered // This ensures the modal is centered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Private Area</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Add content for your private area modal here */}
            <Text>This is the private area.</Text>
            <HStack>
              <PinInput otp mask onChange={(value) => setEnteredPin(value)}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={verifyCorrectPin}>
              Enter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

// Mobile navigation menu
const MobileNav = (props) => {
  const { choosePath, refresh, toggleNav } = props;
  const handleMenuClick = (page) => {
    if (page === "Income_Outcome") {
      choosePath("Income_Outcome");
      console.log("click");
    } else if (page === "Dashboard") {
      choosePath("Dashboard");
    } else if (page === "Montly_Income") {
      choosePath("Montly_Income");
    } else if (page === "Upload_Bank_file") {
      choosePath("Upload_Bank_file");
    } else if (page === "Admin_Panel") {
      choosePath("Admin_Panel");
    }else if (page === 'ReportsList'){
      choosePath('ReportsList')
    } else if (page == 'RefundList') {
      choosePath('RefundList')
    } else if (page == 'RecurringList') {
      choosePath('RecurringList')
    }
    toggleNav();
  };
  return (
    <Box pb={4} display={{ md: "none" }}>
      <Stack as={"nav"} spacing={4}>
        <NavLink onClick={() => handleMenuClick("Income_Outcome")}>
          Income/Outcome
        </NavLink>
        <NavLink onClick={() => handleMenuClick("Upload_Bank_file")}>
          Upload bank file
        </NavLink>
        <NavLink onClick={() => handleMenuClick("RefundList")}>Refund</NavLink>
        <NavLink onClick={() => handleMenuClick("RecurringList")}>Recurring</NavLink>
        <NavLink onClick={() => handleMenuClick("ReportsList")}>Reports</NavLink>
        <NavLink onClick={() => handleMenuClick("Admin_Panel")}>
          Admin Panel
        </NavLink>
        <NavLink onClick={() => handleMenuClick("Dashboard")}>
          Dashboard
        </NavLink>
      </Stack>
    </Box>
  );
};

// Navigation link
const NavLink = ({ children, to, onClick }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={to}
    onClick={(e) => {
      if (onClick) {
        e.preventDefault();
        onClick();
      }
    }}
  >
    {children}
  </Link>
);
