import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { supabase } from "../supabaseClient";
import "../Css/WorkDayTableCss.css";
import { Switch, FormControlLabel } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PaymentIcon from "@mui/icons-material/Payment"; // Icon to indicate unpaid status
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  console.log(row);
  const [open, setOpen] = React.useState(false);
  const [arrWorkTime, setArrWorkTime] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);

  async function getData() {
    try {
      const { data, error } = await supabase.rpc("get_work_time", {
        work_day_id_param: row.id,
      });

      if (error) {
        console.error("Error", error);
      } else {
        setArrWorkTime(data);
        console.log(data);
        // Calculate total after setting the data
        let total = 0;
        data.forEach((item) => {
          total +=
            calculateTimeDifference(item.time_from, item.time_to) *
            item.amount_hour;
        });
        setTotalAmount(total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const calculateTimeDifference = (timeFrom, timeTo) => {
    const startDate = new Date(`1970-01-01T${timeFrom}`);
    const endDate = new Date(`1970-01-01T${timeTo}`);
    const diffMs = endDate - startDate; // difference in milliseconds
    const diffHrs = diffMs / 3600000; // convert milliseconds to hours
    return diffHrs.toFixed(2);
  };
  const togglePaidStatus = async (id, currentStatus) => {
    try {
      const { data, error } = await supabase
        .from("WorkTime") // Replace with your table name
        .update({ paid: !currentStatus }) // Toggle the 'paid' status
        .eq("id", id); // Use the primary key for the record

      if (error) throw error;

      // Return the updated data
      return data;
    } catch (error) {
      console.error("Error updating paid status:", error);
      throw error;
    }
  };

  const handlePaidStatusChange = async (id, currentStatus) => {
    try {
      const updatedRow = await togglePaidStatus(id, currentStatus);

      // Update the state to reflect the changes in the UI
      const updatedWorkTime = arrWorkTime.map((item) =>
        item.id === id ? { ...item, paid: !currentStatus } : item
      );
      setArrWorkTime(updatedWorkTime);
    } catch (error) {
      console.error("Error updating paid status:", error);
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.work_day}
        </TableCell>
        <TableCell align="right">{totalAmount}€</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Worker</TableCell>
                    <TableCell>Time From</TableCell>
                    <TableCell align="right">Time to</TableCell>
                    <TableCell align="right">Total Hours</TableCell>
                    <TableCell align="right">Eur per hour</TableCell>
                    <TableCell align="right">Total amount</TableCell>
                    <TableCell align="right">Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow></TableRow>
                  {arrWorkTime.map((row2) => (
                    <TableRow key={row2.time_from}>
                      <TableCell component="th" scope="row">
                        {row2.worker_name}
                      </TableCell>
                      <TableCell>{row2.time_from}</TableCell>
                      <TableCell align="right">{row2.time_to}</TableCell>
                      <TableCell align="right">
                        {calculateTimeDifference(row2.time_from, row2.time_to)}
                      </TableCell>
                      <TableCell align="right">{row2.amount_hour}€</TableCell>
                      <TableCell align="right">
                        {calculateTimeDifference(row2.time_from, row2.time_to) *
                          row2.amount_hour}
                        €
                      </TableCell>
                      <TableCell align="right">
                        {row2.paid ? (
                          <Tooltip title="Set as unpaid">
                            <IconButton
                              onClick={() =>
                                handlePaidStatusChange(row2.id, true)
                              }
                            >
                              <CheckCircleIcon style={{ color: "green" }} />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Set as paid">
                            <IconButton
                              onClick={() =>
                                handlePaidStatusChange(row2.id, false)
                              }
                            >
                              <PaymentIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    work_day: PropTypes.string.isRequired, // assuming work_day is a string
    name: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired, // assuming id is used as a unique identifier for work_day
    // You might have other properties that need to be included here
    // based on the actual structure of your 'row' data.
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export default function WorkDayTable() {
  const [arrWorkDays, setArrWorkDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { data, error } = await supabase.rpc("get_work_days");
        if (error) throw error;
        setArrWorkDays(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Day</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrWorkDays.map((row) => (
              <Row key={row.work_day} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
