import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Stack,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { supabase } from "../supabaseClient";

function LoadBankFile() {
  const [file, setFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const user_id = JSON.parse(sessionStorage.getItem("user")).id;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }

    setActiveStep(1);

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!["csv", "xls", "xlsx"].includes(fileExtension)) {
      alert("Unsupported file format. Please upload a CSV, XLS, or XLSX file.");
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });

        await uploadData(jsonData);
        setActiveStep(2); // Complete processing
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error processing file:", error);
      alert("Error processing file.");
    }
  };
  function convertDate(dateStr) {
    // Split the date into [month, day, year]
    let parts = dateStr.split('/');

    // Adjust the year to four digits. Assuming all years are 2000+
    let year = parseInt(parts[2], 10);
    year = year < 100 ? year + 2000 : year;

    // Ensure month and day are two digits
    let month = parts[0].padStart(2, '0');
    let day = parts[1].padStart(2, '0');

    // Concatenate into the desired format
    return `${year}-${month}-${day}`;
  }
  function convertToNumeric(str) {
    // Remove commas and convert to a number
    return parseFloat(str.replace(/,/g, ''));
  }
  console.log(convertDate("1/20/24"));  // Output: 2024-01-20

  async function uploadData(jsonData) {
    console.log(jsonData);
    const processedData = jsonData.map((item) => ({
      amount: convertToNumeric(item.Importo),
      description: item.Dettagli,
      date: item.Data ? convertDate(item.Data) : null,
      account_uuid: user_id
    }));
    console.log(processedData)
    const { data, error } = await supabase
      .from("Spesa_money_income_outcome")
      .upsert(processedData);

    if (error) {
      console.error("Error:", error);
    } else {
      console.log("Data uploaded successfully:", data);
    }
  }
  const steps = [
    { title: "Upload", description: "Upload the file" },
    { title: "Process", description: "Process the file" },
    { title: "Elaborated", description: "The file is elaborated" },
  ];
  function serialDateToJSDate(serialDate) {
    const msPerDay = 24 * 60 * 60 * 1000;
    const startDate = new Date(1900, 0, 1);
    const days = serialDate - 1;
    const resultDate = new Date(startDate.getTime() + days * msPerDay);
    return resultDate;
  }


  return (
    <Stack spacing={4} p={4}>
      <Stepper size="lg" colorScheme="yellow" index={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={index < activeStep ? "✅" : ""}
                incomplete={index === activeStep ? "🔃" : ""}
                active={index === activeStep ? "📂" : ""}
              />
            </StepIndicator>
            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Flex direction="column" align="center">
        {activeStep === 0 && (
          <FormControl>
            <Input type="file" onChange={handleFileChange} />
            <Button
              colorScheme="blue"
              mt={4}
              onClick={() => handleUpload()}
              isLoading={activeStep === 1}
            >
              Upload
            </Button>
          </FormControl>
        )}
      </Flex>
    </Stack>
  );
}

export default LoadBankFile;
