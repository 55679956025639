import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { supabase } from "../supabaseClient";

function Config() {
  // Define state variables to hold configuration values
  const [costPerHour, setCostPerHour] = useState(0);
  const [costPerHourOriginal, setCostPerHourOriginal] = useState(0);
  // Handle changes to the costPerHour value
  const handleCostChange = (e) => {
    setCostPerHour(e.target.value);
  };

  // Save the configuration when the admin is done
  const saveConfig = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const { data, error } = await supabase
        .from("Config") // Replace with your table name
        .update({ amount_hour: costPerHour }) // Update the 'amount_hour' column
        .eq("amount_hour", costPerHourOriginal); // Add a WHERE clause to specify the condition

      if (error) {
        console.error("Error updating configuration:", error);
      } else {
        console.log("Configuration updated successfully:", data);
      }
      console.log(costPerHourOriginal);
    } catch (error) {
      console.error("Error updating configuration:", error);
    }
  };
  useEffect(() => {
    console.log("load");
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);
  const getData = async () => {
    const { data, error } = await supabase.from("Config").select("*");

    if (error) {
      console.error("Error", error);
    } else {
      setCostPerHourOriginal(data[0].amount_hour);
      setCostPerHour(data[0].amount_hour);
      console.log(data[0].amount_hour);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 3, maxWidth: 400 }}>
        <Typography variant="h4" gutterBottom>
          Configuration
        </Typography>
        <form onSubmit={saveConfig}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Cost per Hour"
                variant="outlined"
                type="number"
                fullWidth
                value={costPerHour}
                onChange={handleCostChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}

export default Config;
