import logo from "./logo.svg";
import React, { useEffect, useState } from "react";

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BarberinoManagerMain from "./BarberinoManager/Components/BarberinoManagerMain";
import { EditMode } from "./BarberinoManager/Components/EditMode";
import EditModeContainers from "./BarberinoManager/Components/EditModeContainers";
import Login from "./BarberinoManager/Components/LoginPanel";
import Home from "./ManageTools/Home";
import SpeseMain from "./Spese/Components/SpeseMain";
import { ChakraProvider } from "@chakra-ui/react";
import PalestraMain from './Palestra/Components/PalestraMain'
function App() {
  const [needToLog, setNeedToLog] = useState(true);

  function checkLoginAuth() {
    console.log(sessionStorage.getItem("user"));
    if (
      sessionStorage.getItem("user") === null ||
      sessionStorage.getItem("user") == undefined
    ) {
      setNeedToLog(true);
    } else {
      setNeedToLog(false);
    }
    console.log(needToLog);
  }

  const handleSuccessfulLogin = () => {
    setNeedToLog(false);
  };

  useEffect(() => {
    checkLoginAuth();
  }, []);
  const handleRefreshLogout = (data) => {
    setNeedToLog(true);
  };
  return needToLog ? (
    <Login onSuccessfulLogin={handleSuccessfulLogin}> </Login>
  ) : (
    <Routes>
      <Route
        path="/BarberinoManager"
        element={<BarberinoManagerMain logoutRefresh={handleRefreshLogout} />}
      />
      <Route
        path="/Spese"
        element={<SpeseMain logoutRefresh={handleRefreshLogout} />}
      />
        <Route
          path="/Palestra"
          element={<PalestraMain logoutRefresh={handleRefreshLogout} />}
        />
      <Route path="*" element={<Home />} />{" "}
    </Routes>
  );

}
export default App;
