import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Box, useColorModeValue, useTheme } from "@chakra-ui/react";
import { supabase } from "../supabaseClient";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from "@chakra-ui/react";
import { MinusIcon } from "@chakra-ui/icons";
const PieChart = (props) => {
  const theme = useTheme();
  const [dataChart, setDataChart] = useState([0, 0]);
  const { refresh, lock, mod} = props;
  const user_id = JSON.parse(sessionStorage.getItem("user")).id;

  useEffect(() => {
    console.log(lock);
    async function fetchData() {
      if (mod==='total'){
        await getDataTotal();
      }else {
        await getData();
      }
    }
    fetchData();
  }, [refresh, lock]);

  async function getData() {
    try {
      const { data, error } = await supabase.rpc("get_income_outcome", {
        account_uuid_param: user_id,
      });
      if (error) {
        console.error("Error", error);
      } else {
        console.log(data);
        var arrDataChartInsert = [];
        if (lock) {
          arrDataChartInsert.push(0);
          arrDataChartInsert.push(0);
        } else {
          arrDataChartInsert.push(
            data[0].outcome != null ? data[0].outcome : 0
          );
          arrDataChartInsert.push(data[0].income != null ? data[0].income : 0);
        }

        setDataChart(arrDataChartInsert);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function getDataTotal() {
    try {
      const { data, error } = await supabase.rpc("get_income_outcome_total", {
        account_uuid_param: user_id,
      });
      if (error) {
        console.error("Error", error);
      } else {
        console.log(data);
        var arrDataChartInsert = [];
        if (lock) {
          arrDataChartInsert.push(0);
          arrDataChartInsert.push(0);
        } else {
          arrDataChartInsert.push(
            data[0].outcome != null ? data[0].outcome : 0
          );
          arrDataChartInsert.push(data[0].income != null ? data[0].income : 0);
        }

        setDataChart(arrDataChartInsert);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const incomeColor = useColorModeValue(
    theme.colors.green[400],
    theme.colors.green[300]
  );
  const outcomeColor = useColorModeValue(
    theme.colors.red[400],
    theme.colors.red[300]
  );

  const chartData = {
    labels: ["Income", "Outcome"],
    datasets: [
      {
        label: "Income vs Outcome",
        data: dataChart,
        backgroundColor: [incomeColor, outcomeColor],
        borderColor: "white",
        borderWidth: 2,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: useColorModeValue("white", "gray.700"),
        titleColor: useColorModeValue("black", "white"),
        bodyColor: useColorModeValue("gray.800", "gray.300"),
        borderColor: useColorModeValue("gray.300", "gray.600"),
        borderWidth: 1,
        cornerRadius: 3,
        caretSize: 5,
      },
    },
  };

  return (
    <>
      <Box textAlign="center" mb={4}>
        <StatGroup>
          <Box mx={4}>
            {" "}
            {/* Add margin on the x-axis */}{" "}
            <Stat>
              <StatLabel> Income </StatLabel>{" "}
              {lock ? (
                <MinusIcon />
              ) : (
                <StatNumber> {dataChart[0].toFixed(2)}€ </StatNumber>
              )}{" "}
            </Stat>{" "}
          </Box>
          <Box mx={4}>
            {" "}
            {/* Add margin on the x-axis */}{" "}
            <Stat>
              <StatLabel> Outcome </StatLabel>{" "}
              {lock ? (
                <MinusIcon />
              ) : (
                <StatNumber> {dataChart[1].toFixed(2)}€ </StatNumber>
              )}{" "}
            </Stat>{" "}
          </Box>
          <Box mx={4}>
            {" "}
            {/* Add margin on the x-axis */}{" "}
            <Stat>
              <StatLabel> Total savings </StatLabel>{" "}
              {lock ? (
                <MinusIcon />
              ) : (
                <StatNumber>
                  {" "}
                  {(dataChart[0] + dataChart[1]).toFixed(2)}€{" "}
                </StatNumber>
              )}{" "}
            </Stat>{" "}
          </Box>{" "}
        </StatGroup>{" "}
      </Box>{" "}
      <Box
        boxShadow="lg"
        borderRadius="md"
        p={3}
        bg={useColorModeValue("white", "gray.800")}
      >
        <Pie data={chartData} options={options} />{" "}
      </Box>{" "}
    </>
  );
};

export default PieChart;
