import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel'; // Import Cancel Icon
import { supabase } from "../supabaseClient";
import _ from 'lodash';
import '../Css/EditMode.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { forwardRef, useImperativeHandle } from 'react';
import Tooltip from '@mui/material/Tooltip';

const EditMode = forwardRef((props, ref) => {
    const {obj} = props;
    const [isEditing, setIsEditing] = useState(false);
    const [arrWorkerDetails, setArrWorkerDetails] = useState([]);
    const [origArrWorkerDetails, setOrigArrWorkerDetails] = useState([]);
    const [arrWorkers, setArrWorkers] = useState([]);

    useEffect(() => {
        getWorkerData();
        getWorkers();
    }, []);

    const getWorkerData = async () => {
        const { data, error } = await supabase
            .rpc('get_detail_worker_day_time', { day_param: obj.work_day });
        if (error) {
            console.error("Error", error);
        } else {
            setArrWorkerDetails(data);
            setOrigArrWorkerDetails(_.cloneDeep(data)); // Lodash to deep clone the array
        }
    };
    const getWorkers = async () => {
        try {
            let { data, error } = await supabase.from("Worker").select("*");
            if (error) throw error;
            setArrWorkers(data);
        } catch (error) {
            console.error("Error fetching workers:", error);
        }
    };

    const handleEditClick = (index) => {
        // Clone the current arrWorkerDetails
        const newArr = [...arrWorkerDetails];
        // Toggle the editing state of the specific item
        newArr[index].isEditing = !newArr[index].isEditing;
        // Set the modified array back to state
        setArrWorkerDetails(newArr);
    };

    const handleCancelClick = (index) => {
        getWorkerData(); // Re-fetch the data to reset changes
        const newArr = [...arrWorkerDetails];
        newArr[index].isEditing = false;
        setArrWorkerDetails(newArr);
    };

    const handleSaveClick = async (index) => {
        // Implement save logic here
        // After saving, you can turn off editing mode
        var obj1 = arrWorkerDetails[index]
        var obj2 = origArrWorkerDetails[index]
        delete obj1.isEditing; // Removes 'property1'
        console.log(obj1)
        if(obj1.id == null){
            console.log('need to create new one and save into db')
            createWorkTime(obj1)
        }else{
            //update

        }
        if(!( _.isEqual(obj1,obj2))){
            console.log(obj1.id)
            console.log(obj1)
            await updateWorkTime(obj1)
        }
        // const newArr = [...arrWorkerDetails];
        // newArr[index].isEditing = false;
        // setArrWorkerDetails(newArr);
        await getWorkerData()
    };

    const handleChange = (index, field, value) => {
        const newArr = [...arrWorkerDetails];
        newArr[index][field] = value;
        setArrWorkerDetails(newArr);

    };
    async function createWorkTime(object){
        console.log(object)
        try {
            // Update database with new shift
            const { data, error } = await supabase
              .from('WorkTime') // Your table name
              .insert([{ time_from: object.time_from, time_to: object.time_to, notes: object.notes, worker_id: object.worker_id , work_day_id: obj.id}]); // Adjust based on your schema
      
            if (error) throw error;
      
            // Update local state with new shift
          } catch (error) {
            console.error('Error creating shift:', error);
            // Optionally: set an error message in state to display to the user
          } 
      }
    async function updateWorkTime(obj) {
        console.log('Updating with:', obj);
        const { data, error } = await supabase
            .from('WorkTime')
            .update({ time_from: obj.time_from, time_to: obj.time_to, notes: obj.notes })
            .eq('id', obj.id);
      
        if (error) {
            console.error('Error updating:', error);
        } else {
            console.log('Update successful:', data);
            // Consider setting state here or calling getWorkerData() after this line
        }
    }
    const handleDelete = async (id) => {
        try {
            const { error } = await supabase
                .from('WorkTime') // Replace 'WorkTime' with your actual table name
                .delete()
                .eq('id', id); // Replace 'id' with the column you use to uniquely identify your records

            if (error) throw error;
            
            // Refresh the data to reflect the deletion
            getWorkerData();
            console.log(`Deleted record with id ${id}`);
        } catch (error) {
            console.error('Error deleting record:', error);
        }
    };
    const handleAddNewWorkTime = () => {
        const newWorkTime = {
          id: null,
          worker: '',
          time_from: '',
          time_to: '',
          notes: '',
          isEditing: true,
        };
        setArrWorkerDetails([newWorkTime, ...arrWorkerDetails]);
        
      };
      useImperativeHandle(ref, () => ({
        handleAddNewWorkTime,
    }));
    return (
        <>
       
            {arrWorkerDetails.map((worker_details, index) => (
    <Card className="Card" key={index}>
      <CardContent className="CardContent">
      <Box className="Box" display="flex" justifyContent="space-between" alignItems="center">
      <Typography className="Typography-h6" variant="h6">{worker_details.worker} shift</Typography>
                            {worker_details.isEditing ? (
                                <>
                                    <Tooltip title="Save shift" placement="top">
                                    <IconButton onClick={() => handleSaveClick(index)} size="small">
                                        <SaveIcon />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete shift" placement="top">
                                    <IconButton onClick={() => handleDelete(worker_details.id)} size="small" className="deleteButton">
                                        <DeleteIcon />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Cancel edit shift" placement="top">
                                    <IconButton onClick={() => handleCancelClick(index)} size="small">
                                        <CancelIcon />
                                    </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <IconButton onClick={() => handleEditClick(index)} size="small">
                                    <EditIcon />
                                </IconButton>
                                
                            )}
                        </Box>
                        {worker_details.isEditing ? (
                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
                               <Select
                                    fullWidth
                                    size="small"
                                    value={worker_details.worker_id}
                                    onChange={(e) => handleChange(index, 'worker_id', e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem value="" disabled>
                                        Select a worker
                                    </MenuItem>
                                    {arrWorkers.map((worker) => (
                                        <MenuItem key={worker.id} value={worker.id}>{worker.name}</MenuItem>
                                    ))}
                                    </Select>
                                 <TextField
                                    fullWidth
                                    size="small"
                                    label="Time from"
                                    type="time"
                                    value={worker_details.time_from}
                                    onChange={(e) => handleChange(index, 'time_from', e.target.value)}
                                    margin="dense"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    />
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Time to"
                                    type="time"
                                    value={worker_details.time_to}
                                    onChange={(e) => handleChange(index, 'time_to', e.target.value)}
                                    margin="dense"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    />
                                 <TextField
                                    fullWidth
                                    size="small"
                                    label="Description"
                                    value={worker_details.notes}
                                    onChange={(e) => handleChange(index, 'notes', e.target.value)}
                                    margin="dense"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ mt: 1 }}>
                            <Typography variant="body2"><b>Worker:</b> {worker_details.worker}</Typography>
                            <Typography variant="body2"><b>Start Time:</b> {worker_details.time_from}</Typography>
                            <Typography variant="body2"><b>End Time:</b> {worker_details.time_to}</Typography>
                            <Typography variant="body2"><b>Description:</b> {worker_details.notes}</Typography>
                          </Box>
                        )}
                    </CardContent>
                </Card>
            ))}
        </>
    );
});

export default EditMode;
