import {
    Card, CardHeader, CardBody, CardFooter,
    SimpleGrid,
    Heading,
    Text,
    Button,
    Box,
    AbsoluteCenter,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { anticipate } from 'framer-motion';
import { setRef } from '@blueprintjs/core';

export default function Recurring() {
    const user_id = JSON.parse(sessionStorage.getItem("user")).id;
    const [arrRecurring, setArrRecurring] = useState([])
    const toast = useToast();
    const [refresh, setRefresh] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)

    // Function to calculate the total amount whenever arrRecurring is updated
    const calculateTotalDeb = () => {
        let total = 0;
        arrRecurring.forEach((row) => {
            total += row.amount;
        });
        setTotalAmount(total);
    }

    useEffect(() => {
        async function fetchData() {
            await getData();
        }
        fetchData();
    }, [refresh]);

    useEffect(() => {
        // Calculate total amount whenever arrRecurring is updated
        calculateTotalDeb();
    }, [arrRecurring]);

    const handleRecurring = async (row) => {
        console.log(row)
        const { error } = await supabase
            .from("Spesa_money_income_outcome")
            .update({
                montly_recurring: !row.montly_recurring,
            })
            .eq("id", row.id);

        if (error) {
            console.error("Error updating:", error);
        } else {
            toast({
                title: "Update successful.",
                description: "The selected row has been updated.",
                status: "success",
                duration: 1000,
                isClosable: true,
            });
            setRefresh(true);
        }
    };

    async function getData() {
        try {
            const { data, error } = await supabase
                .from("Spesa_money_income_outcome")
                .select("*")
                .eq("account_uuid", user_id)
                .eq("montly_recurring", true)
                .order("date", { ascending: false });

            if (error) {
                console.error("Error", error);
            } else {
                setArrRecurring(data);
                console.log(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <>
            <SimpleGrid columns={1} spacing={10}>
                <Card>
                    <CardHeader>
                        <Heading size='md'>{totalAmount}</Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>Total recurring</Text>
                    </CardBody>
                    <CardFooter>
                        <SimpleGrid columns={2} spacing={3}>
                            <Button>Remove all recurring</Button>
                        </SimpleGrid>
                    </CardFooter>
                </Card>
            </SimpleGrid>
            <br></br>
            {totalAmount != 0 ? < Text fontSize='5xl'>Montly recurring</Text > : <Text>No montly recurring</Text>}
            <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
                {arrRecurring.map((row, index) => (
                    <Card key={index}>
                        <CardHeader>
                            <Heading size='md'>{row.amount}</Heading>
                        </CardHeader>
                        <CardBody>
                            <Text>{row.description}</Text>
                            <Text>{row.date}</Text>
                        </CardBody>
                        <CardFooter>
                            <SimpleGrid columns={2} spacing={3}>
                                <Button onClick={() => { handleRecurring(row) }}>Stop Recurring</Button>
                            </SimpleGrid>
                        </CardFooter>
                    </Card>
                ))}
            </SimpleGrid>
        </>
    );
}
