import React, { useEffect, useState } from "react";
import WorkDayTable from "./WorkDayTable";
import NavBar from "./NavBar";
import WorkerContainers from "./WorkerContainers";
import Config from "./Config";
import "../Css/BarberinoManagerMain.css";
import { TableSwitch } from "./TableSwitch";
import Edit from "@mui/icons-material/Edit";
import EditModeContainers from "./EditModeContainers";

function BarberinoManagerMain(props) {
  const { logoutRefresh } = props;
  const [path, setPath] = useState("View");
  const handleRefresh = (data) => {
    logoutRefresh(true);
  };

  const navigate = (data) => {
    setPath(data);
  };
  return (
    <div>
      {/* <div className="workerContainers">
        <WorkerContainers refresh={handleRefresh} />
      </div>
      <br></br>
      <div className="workDayTable">
        <TableSwitch />
      </div> */}
      <NavBar choosePath={navigate} refresh={handleRefresh}></NavBar>
      {path == "Edit" && <EditModeContainers> </EditModeContainers>}
      {path == "View" && <TableSwitch></TableSwitch>}
      {path == "Config" && <Config></Config>}
      {/* <TableSwitch />
      <EditModeContainers> */}
    </div>
  );
}

export default BarberinoManagerMain;
