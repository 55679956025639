import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./ToolSelector.css";
const ToolCard = ({ tool, isSelected, onSelect }) => {
  const cardClass = isSelected ? `card selected` : `card`;
  return (
    <div className={cardClass} onClick={onSelect}>
      <p>{tool.name}</p>
    </div>
  );
};
const tools = [
  { id: 1, name: "Barberino", link: "/BarberinoManager" },
  { id: 2, name: "Spese", link: "/Spese" },
  { id: 3, name: "Palestra", link: "/Palestra" },
];


const Home = () => {
  const [selectedTool, setSelectedTool] = useState(null);
  const navigate = useNavigate();

  const handleSelectTool = (tool) => {
    setSelectedTool(tool);
    navigate(tool.link); // Navigate to the tool's link
  };

  return (
    <div
      className="tool-selector-container"
      role="region"
      aria-labelledby="tool-selector-heading"
    >
      <div className="cards-container" aria-live="polite">
      {tools.map((tool, index) => (
      <ToolCard
        key={tool.id}
        tool={tool}
        style={{ animationDelay: `${index * 100}ms` }} // Staggered delay
        isSelected={selectedTool?.id === tool.id}
        onSelect={() => handleSelectTool(tool)}
      />
    ))}

      </div>
    </div>
  );
};

export default Home;
