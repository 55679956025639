import React, { useState, useEffect } from "react";
import "../Css/EditModeContainers.css";
import { supabase } from "../supabaseClient";
import WorkerContainers from "./WorkerContainers";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Grid, Box, Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"; // Updated import
import { useRef } from "react";
import EditMode from "./EditMode";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
// ... other imports ...
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // Import the date adapter you prefer
import Tooltip from "@mui/material/Tooltip";

export default function EditModeContainers(props) {
  const [arrDataByDate, setArrDataByDate] = useState([]);
  const [editingCard, setEditingCard] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [sendNewWorkTimeAction, setSendNewWorkTimeAction] = useState(false);
  const editModeRef = useRef();
  const { logoutRefresh } = props;

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  useEffect(() => {
    getDayData();
    // Delay to ensure items are rendered before the transition starts
    const timer = setTimeout(() => {
      setArrDataByDate((prevData) =>
        prevData.map((item) => ({ ...item, loaded: true }))
      );
    }, 500); // Adjust delay as needed
    return () => clearTimeout(timer);
  }, []);

  const refresh = () => {
    getDayData();
  };

  const getDayData = async () => {
    const { data, error } = await supabase
      .from("distinct_work_day")
      .select("*");

    if (error) {
      console.error("Error", error);
    } else {
      setArrDataByDate(data);
    }
  };

  const handleDeleteDay = async (work_day) => {
    const { error } = await supabase
      .from("WorkDay")
      .delete()
      .eq("work_day", work_day);

    if (error) {
      console.error("Error deleting work day:", error);
    } else {
      setArrDataByDate(
        arrDataByDate.filter((day) => day.work_day !== work_day)
      );
    }
  };

  const handleEditToggle = (cardId) => {
    if (editingCard === cardId) {
      // If the same button is clicked again, close the editor
      setEditingCard(null);
    } else {
      // Open the editor for the clicked card
      setEditingCard(cardId);
    }
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleAddDay = async () => {
    handleCloseDialog(); // Close the dialog

    if (!selectedDate) {
      alert("Please select a date.");
      return;
    }

    const newWorkDay = {
      work_day: new Date(selectedDate).toDateString(),
    };

    try {
      const { data, error } = await supabase
        .from("WorkDay")
        .insert([newWorkDay]);

      if (error) throw error;
      // setArrDataByDate([...arrDataByDate, data[0]]);  // You might not need this if you're refreshing the whole list
      setSelectedDate(""); // Reset the selected date
      await refresh(); // Call refresh to fetch and update data
      setSelectedDate(new Date());
    } catch (error) {
      console.error("Error creating new work day:", error);
    }
  };
  const addWorktime = () => {
    if (editModeRef.current) {
      editModeRef.current.handleAddNewWorkTime();
    }
  };
  const handleRefresh = (data) => {
    logoutRefresh(true);
  };
  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Tooltip title="Add New Work Day" placement="left">
          <Fab
            color="primary"
            aria-label="add"
            style={{
              position: "fixed", // Fixed position
              bottom: "20px", // 20px from the bottom
              right: "20px", // 20px from the right
              zIndex: 1000, // Ensure it's above other items
            }}
            onClick={handleOpenDialog}
          >
            <AddIcon />
          </Fab>
        </Tooltip>

        <div className="scrollContainer">
          {arrDataByDate.map((workDayItem) => (
            <div key={workDayItem.id} className="dayContainer">
              <div className="cardHeader">
                <h2>{workDayItem.work_day}</h2>
                <Tooltip title="Add new shift" placement="top">
                  <IconButton onClick={addWorktime} color="primary">
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete workday" placement="top">
                  <IconButton
                    onClick={() => handleDeleteDay(workDayItem.work_day)}
                    className="deleteButton"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="cardContent">
                <EditMode
                  ref={editModeRef} // Attach the ref here
                  obj={workDayItem}
                  refresh={refresh}
                  className="editModeCard"
                  uniqueId={workDayItem.id}
                  isEditing={editingCard === workDayItem.id}
                  onEditToggle={() => handleEditToggle(workDayItem.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </Grid>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add a New Work Day</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {" "}
            {/* Use the appropriate date adapter */}
            <DatePicker
              label="Work Day"
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddDay}>Add</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
}
