import * as React from "react";
import { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import AvatarGroup from "@mui/joy/AvatarGroup";
import { supabase } from "../supabaseClient";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import logoImage from "../Images/BarberinoManagerIcon3.png"; // Adjust the file name as necessary

const pages = ["Edit", "View summary", "Config"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar(props) {
  const { choosePath, refresh } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [arrWorkers, setArrWorkers] = useState([]);
  const [hoveredWorkerId, setHoveredWorkerId] = useState(null);
  const [open, setOpen] = useState(false);
  const [newWorker, setNewWorker] = useState({ name: "" });
  const navigate = useNavigate();

  useEffect(() => {
    console.log("load");
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);
  async function getData(props) {
    try {
      const { data, error } = await supabase.from("Worker").select("*");
      if (error) {
        console.error("Error", error);
      } else {
        setArrWorkers(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const handleMenuClick = (page) => {
    if (page === "Edit") {
      choosePath("Edit");
    } else if (page === "View summary") {
      choosePath("View");
    } else if (page === "Config") {
      choosePath("Config");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (data) => {
    setAnchorElUser(null);
    if (data == "Logout") {
      logout();
    } else if (data == "Dashboard") {
      dashboard();
    }
    console.log(data);
  };
  const handleMouseEnter = (workerId) => {
    setHoveredWorkerId(workerId);
  };
  const handleMouseLeave = () => {
    setHoveredWorkerId(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteWorker = async (workerId) => {
    try {
      // Make a call to the Supabase API to delete the worker by ID.
      const { error } = await supabase
        .from("Worker") // Replace with your table name
        .delete()
        .eq("id", workerId); // Ensure this is the primary key column for your workers

      if (error) {
        throw error;
      }

      // If the deletion is successful, update the state to remove the worker from the list.
      setArrWorkers(arrWorkers.filter((worker) => worker.id !== workerId));
      console.log(`Worker with ID: ${workerId} has been deleted.`);
    } catch (error) {
      console.error("Error deleting worker:", error);
      // Optionally: set an error message in state to display to the user.
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorker({ ...newWorker, [name]: value });
  };
  const handleAddWorker = async () => {
    try {
      const { data, error } = await supabase.from("Worker").insert([newWorker]);
      if (error) throw error;
      // Update your local state or do something with the response
      getData();
      handleClose(); // Close the dialog
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };
  const handleEditClick = () => {
    choosePath("Edit");
  };

  const handleViewSummaryClick = () => {
    choosePath("View");
  };

  const handleConfigClick = () => {
    choosePath("Config");
  };
  function logout() {
    console.log("Lock button pressed");
    sessionStorage.removeItem("user");
    console.log(sessionStorage.getItem("user"));
    refresh(true);
    navigate("/"); // Navigate to the home route
  }
  function dashboard() {
    console.log("go dashboard");
    navigate("/");
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={logoImage}
            style={{ height: "50px" }} // Adjust the size as needed
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleMenuClick(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              key="Edit"
              onClick={handleEditClick}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Edit
            </Button>
            <Button
              key="View Summary"
              onClick={handleViewSummaryClick}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              View Summary
            </Button>
            <Button
              key="Config"
              onClick={handleConfigClick}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Config
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <AvatarGroup
              className="avatarGroup"
              sx={{
                marginRight: 15,
                ".MuiAvatar-root": {
                  width: { xs: 24, sm: 30, md: 40 }, // Adjust the size based on the screen width
                  height: { xs: 24, sm: 30, md: 40 },
                },
              }}
            >
              {arrWorkers
                .slice(0, window.innerWidth < 600 ? 3 : arrWorkers.length)
                .map((worker) => (
                  <Tooltip title={worker.name} key={worker.id}>
                    <Box
                      onMouseEnter={() => handleMouseEnter(worker.id)}
                      onMouseLeave={handleMouseLeave}
                      className={`avatarContainer ${
                        hoveredWorkerId === worker.id ? "hovered" : ""
                      }`}
                      sx={{ display: "inline-flex", alignItems: "center" }}
                    >
                      {worker.imageUrl ? (
                        <Avatar
                          alt={worker.name}
                          src={worker.imageUrl}
                          className="avatar"
                        />
                      ) : (
                        <Avatar className="avatar">
                          {worker.name.charAt(0)}
                        </Avatar>
                      )}
                      {hoveredWorkerId === worker.id && (
                        <IconButton
                          className="deleteButton"
                          size="small"
                          onClick={() => handleDeleteWorker(worker.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </Tooltip>
                ))}
              <Tooltip className="tooltip">
                <IconButton onClick={handleClickOpen} className="addButton">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </AvatarGroup>

            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Dialog open={open} onClose={handleClose} className="customDialog">
        <DialogTitle className="dialogTitle">Add New Worker</DialogTitle>
        <DialogContent className="dialogContent">
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={handleClose} className="dialogButton">
            Cancel
          </Button>
          <Button onClick={handleAddWorker} className="dialogButton">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
export default ResponsiveAppBar;
