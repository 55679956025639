import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Checkbox,
  CheckboxGroup,
  ButtonGroup,
} from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import PieChart from "./Chart";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { RepeatClockIcon, SmallCloseIcon, MinusIcon } from "@chakra-ui/icons";
import { Badge } from '@chakra-ui/react'

Chart.register(ArcElement, Tooltip, Legend);

export default function Dashboard(props) {
  const { refresh, lock } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [arrSpesaIncomeOutCome, setArrSpesaIncomeOutCome] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const financialData = [5000, 3000]; // Example data for the charts
  const [selectedRow, setSelectedRow] = useState(null);
  const toast = useToast();
  const [data, setData] = useState([]);
  const user_id = JSON.parse(sessionStorage.getItem("user")).id;
  const [editData, setEditData] = useState({
    description: "",
    amount: 0,
    date: new Date(),
    montly_recurring: false,
    anticipated: false
  });
  const handleRowClick = (row) => {
    console.log(row)
    setEditData({
      id: row.id,
      description: row.description,
      amount: row.amount,
      date: row.date ? new Date(row.date) : new Date(),
      montly_recurring:
        row.montly_recurring === true || row.montly_recurring === "true",
      anticipated:
        row.anticipated === true || row.anticipated === "true",
    });
    setIsChecked(row.montly_recurring); // Set the isChecked state
    onOpen();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  useEffect(() => {
    async function fetchData() {
      await getData(lock);
      if (deleted) {
        setDeleted(false);
      }
    }
    fetchData();
  }, [refresh, deleted, lock]);

  async function getData(lockTable) {
    console.log(lockTable);
    try {
      const { data, error } = await supabase
        .from("Spesa_money_income_outcome")
        .select("*")
        .eq("account_uuid", user_id)
        .order("date", { ascending: false });

      if (error) {
        console.error("Error", error);
      } else {
        // if (lockTable) {
        //   data.forEach((x) => {
        //     x.amount = "*";
        //     x.description = "*";
        //   });
        // }
        setArrSpesaIncomeOutCome(data);
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const editRow = (row) => {
    // Logic to edit the row
    onClose();
  };

  const deleteRow = async () => {
    console.log("delete clicked" + structuredClone(editData.id));
    const { error } = await supabase
      .from("Spesa_money_income_outcome")
      .delete()
      .eq("id", editData.id);

    if (!error) {
      setDeleted(true);
      toast({
        title: "Deletion successful.",
        description: "The selected row has been deleted.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    }
    onClose();
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSave = async () => {
    if (editData) {
      const { error } = await supabase
        .from("Spesa_money_income_outcome")
        .update({
          description: editData.description,
          amount: editData.amount,
          date: editData.date.toISOString().split("T")[0],
          montly_recurring: editData.montly_recurring,
          anticipated: editData.anticipated, // Adjust the date format if needed
          reimbursed: editData.reimbursed,
        })
        .eq("id", editData.id);

      if (error) {
        console.error("Error updating:", error);
      } else {
        toast({
          title: "Update successful.",
          description: "The selected row has been updated.",
          status: "success",
          duration: 1000,
          isClosable: true,
        });
        setDeleted(true); // To refresh the data list
      }
    }
    onClose();
  };
  const handleClose = () => {
    onClose(); // Original onClose functionality
  };
  const handleEditChange = (value, field) => {
    setEditData({ ...editData, [field]: value });
  };
  const handleEditChangeCheckBoxMontlyRecurring = (event) => {
    setEditData((prevState) => ({
      ...prevState,
      montly_recurring: event.target.checked,
    }));
  };
  const handleEditChangeCheckBoxAnticipated = (event) => {
    setEditData((prevState) => ({
      ...prevState,
      anticipated: event.target.checked,
    }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <>
      <Flex
        direction={["column", "column", "row"]}
        justifyContent="space-evenly"
        alignItems="center"
        my={5}
      >
        <Box
          boxShadow="lg"
          borderRadius="md"
          p={3}
          minWidth={["100%", "100%", "300px"]} // Adjust based on screen size
          maxWidth="100%"
          textAlign="center"
        >
          <Text fontSize="xl" fontWeight="bold" mb={3}>
            Montly income & outcome
          </Text>
          <PieChart lock={lock} refresh={refresh} />
        </Box>
        <Box
          boxShadow="lg"
          borderRadius="md"
          p={3}
          minWidth="300px" // Optional: Set a minimum width
          maxWidth="100%" // Optional: Set a maximum width
          minHeight="300px" // Optional: Set a minimum height
          maxHeight="100%" // Optional: Set a maximum height
          textAlign="center" // Center align text
        >
          <Text fontSize="xl" fontWeight="bold" mb={3}>
            Total income & outcome
          </Text>
          <PieChart mod='total' lock={lock} refresh={refresh} />
        </Box>
        {/* <Box textAlign="center" mb={4}>
                      <Text fontSize="xl" fontWeight="bold" mb={2}>
                        Outcome Chart
                      </Text>
                      <PieChart data={financialData} />
                    </Box> */}
      </Flex>
      <TableContainer>
        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th> Amount </Th> <Th> Date </Th> <Th> Description </Th>
              <Th> Extra </Th>
            </Tr>
          </Thead>
          {/* <TableCaption> Imperial to metric conversion factors </TableCaption> */}
          <Tbody>

            {paginatedData.map((row, index) => (
              <Tr key={index} onClick={() => handleRowClick(row)}>

                {lock ? (
                  <Td>
                    <MinusIcon> </MinusIcon>
                  </Td>
                ) : (
                  <Td isNumeric> {row.amount}€ </Td>
                )}
                <Td> {row.date} </Td>
                {lock ? (
                  <Td>
                    <MinusIcon> </MinusIcon>
                  </Td>
                ) : (
                  <Td style={{ whiteSpace: "normal", wordWrap: "break-word" }}>

                      {row.description}
                  </Td>
                )}
                <Td>
                  <Stack direction='row'>
                    {row.montly_recurring && (
                      <Badge colorScheme='purple'>Recurring</Badge>
                    )}
                    {row.anticipated && (
                      <Badge colorScheme='yellow'>Anticipated</Badge>
                    )}
                  </Stack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex justifyContent="center" mt={4}>
        <ButtonGroup isAttached>
          <Button
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          <Button
            isDisabled={currentPage * itemsPerPage >= data.length}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </ButtonGroup>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={handleClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px"> Edit </DrawerHeader>
          <DrawerBody>
            <Stack spacing="5">
              <Box>
                <FormLabel htmlFor="description"> Description </FormLabel>
                <Input
                  value={editData.description}
                  onChange={(e) =>
                    handleEditChange(e.target.value, "description")
                  }
                  placeholder="Please enter a description"
                />
              </Box>
              <Box>
                <FormLabel htmlFor="amount"> Amount </FormLabel>
                <NumberInput
                  value={editData.amount}
                  onChange={(value) => handleEditChange(value, "amount")}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
              <Box>
                <FormLabel htmlFor="date"> Date </FormLabel>
                <ReactDatePicker
                  selected={editData.date}
                  onChange={(date) => handleEditChange(date, "date")}
                  dateFormat="yyyy-MM-dd" // Update the format as per your requirement
                />
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={2}>
                  Montly recurring
                </Text>
                <Checkbox
                  colorScheme="green"
                  isChecked={editData.montly_recurring}
                  onChange={handleEditChangeCheckBoxMontlyRecurring}
                >
                  {editData.montly_recurring ? <Text fontSize="md" fontWeight="semibold" mb={2}>
                    Recurring
                  </Text> : <Text fontSize="md" fontWeight="semibold" mb={2}>
                    Not Recurring
                  </Text>}
                </Checkbox>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="semibold" mb={2}>
                  Anticipated
                </Text>
                <Checkbox
                  colorScheme="green"
                  isChecked={editData.anticipated}
                  onChange={handleEditChangeCheckBoxAnticipated}
                >
                  {editData.anticipated ? <Text fontSize="md" fontWeight="semibold" mb={2}>
                    Anticipated
                  </Text> : <Text fontSize="md" fontWeight="semibold" mb={2}>
                    Not anticipated
                  </Text>}
                </Checkbox>
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              mr={3}
              onClick={handleSave}
            >
              Submit
            </Button>
            <Button
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={deleteRow}
            >
              Delete
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
